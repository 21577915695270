<template>
  <div class="content" ref="xxx">
    <p class="lianxi">骨骼发育的特性</p>
    <p class="lianxiadress">根据大数据统计，儿童骨骼发育具有以下五大特性</p>
    <div class="shuju">
      <div>
        <span></span>
        <h3>足弓发育测评</h3>
        <div>
          <i></i>
          <span>测量并评估人体足弓发育不良或正常</span>
        </div>
        <div>
          <i></i>
          <span> 光学反射足底印记，系统自动计算足弓比值</span>
        </div>
        <div>
          <i></i>
          <span> 对预防足部问题和保护足弓起着重要的意义</span>
        </div>
      </div>
      <div>
        <span></span>
        <h3>跟骨发育测评</h3>
        <div>
          <i></i>
          <span>测量并评估人体跟骨</span>
        </div>
        <div>
          <i></i>
          <span>系统自动计算出跟骨发育详细数值</span>
        </div>
        <div>
          <i></i>
          <span>对预防足部问题和保护跟骨处于中立位起着重要的意义</span>
        </div>
      </div>
      <div>
        <span></span>
        <h3>足底压力分析</h3>
        <div>
          <i></i>
          <span>测量人体足底压力分布情况</span>
        </div>
        <div>
          <i></i>
          <span>获取静态和动态下，足底压力分布情况</span>
        </div>
        <div>
          <i></i>
          <span>对预防足部问题和缓解足底受力起着重要的意义</span>
        </div>
      </div>
      <div>
        <span></span>
        <h3>3D足部扫描</h3>
        <div>
          <i></i>
          <span>扫描足部360°，获取足部三维模型</span>
        </div>
        <div>
          <i></i>
          <span>初步评估足弓及跟骨发育情况</span>
        </div>
        <div>
          <i></i>
          <span>为个性化定制提供重要的数据</span>
        </div>
      </div>
      <div>
        <span></span>
        <h3>体格测量</h3>
        <div>
          <i></i>
          <span>测量身高</span>
        </div>
        <div>
          <i></i>
          <span>测量体重</span>
        </div>
        <div>
          <i></i>
          <span>作为其他部位发育的参考依据</span>
        </div>
      </div>
    </div>
    <p class="lianxi">骨骼测评匹配建议</p>
    <p class="lianxiadress">根据测评数据为孩子提出适合他的运动建议或矫正产品</p>
    <div class="biaoge">
      <div>
        <img src="../assets/image/opin01.jpg" alt="" />
      </div>
      <div class="biaoge-right">
        <div class="topth">
          <span style="color: #fff" class="firstspan">发育指数</span>
          <span style="color: #fff" class="secondspan">匹配建议</span>
        </div>
        <div>
          <span style="color: #000" class="firstspan">0—20</span>
          <span style="color: #000" class="secondspan"
            >建议去专业的儿童医院</span
          >
        </div>
        <div>
          <span style="color: #000" class="firstspan">20—60</span>
          <span style="color: #000" class="secondspan"
            >防护气垫C3 防护气垫C4 矫形鞋</span
          >
        </div>
        <div>
          <span style="color: #000" class="firstspan">60—80</span>
          <span style="color: #000" class="secondspan"
            >防护气垫C2 防护气垫C3 江博士</span
          >
        </div>
        <div class="botth">
          <span style="color: #000" class="firstspan">80—100</span>
          <span style="color: #000" class="secondspan"
            >防护气垫C1 防护气垫C2 防护气垫C3 专业的运动指导建议</span
          >
        </div>
      </div>
    </div>

    <div class="dianji">
      <div>
        <div>
          <img src="../assets/image/img_zufycp.png" alt="" />
        </div>
        <p>足部发育测评</p>
      </div>
      <div>
        <div @click="xiazhifayu('xiazhi')">
          <img src="../assets/image/img_xzfycp.png" alt="" />
          <span>
            <p>点击</p>
            <p>查看</p>
          </span>
        </div>
        <p>下肢发育测评</p>
      </div>
      <div>
        <div @click="xiazhifayu('qugan')">
          <img src="../assets/image/img_qgfycp.png" alt="" />
          <span>
            <p>点击</p>
            <p>查看</p>
          </span>
        </div>
        <p>躯干发育测评</p>
      </div>
      <div>
        <div @click="xiazhifayu('jichu')">
          <img src="../assets/image/img_jcfaycp.png" alt="" />
          <span>
            <p>点击</p>
            <p>查看</p>
          </span>
        </div>
        <p>基础发育测评</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    xiazhifayu(item){
      this.$emit('huanzujian',item)
      //  console.log(this.$refs.xxx.scrollTop,"1111")
    }
  }

};
</script>


<style lang="scss" scoped>

.address-img {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
  }
}
.shuju {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  height: 700px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 15%;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  > div {
    width: 33%;
    text-align: left;
    padding: 0 1%;
    > span {
      display: inline-block;
      width: 50px;
      height: 6px;
      border-radius: 3px;
      background-color: #74a85d;
      margin-top: 50px;
    }
    h3 {
      font-size: 25px;
      margin-top: 20px;
      font-weight: 400;

      color: #74a85d;
    }
    > div {
      display: flex;
      box-sizing: border-box;
      margin-top: 20px;
      i {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #74a85d;
        margin-right: 10px;
        margin-top: 5px;
      }
      span {
        color: #9d9d9d;
      }
    }
    p {
      width: 100%;

      margin-top: 30px;
      color: #9d9d9d;
      text-align: left;
    }
  }
}
.biaoge {
  display: flex;
  justify-content: left;
  width: 100%;
  height: 400px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 10%;
  > div {
    width: 50%;
    img {
      margin-left: 20%;
    }
  }
  .biaoge-right {
    width: 600px;
    height: 303px;
    border-radius: 10px;
    box-shadow: 0 0 15px #ccc;
    > div {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #ccc;
      span {
        color: #000;
        text-align: center;
        line-height: 60px;
      }
      .firstspan {
        width: 29%;
        border-right: 1px solid #ccc;
      }
      .secondspan {
        width: 69%;
      }
    }
    .topth {
      background-color: #3f8e0c;
      border-radius: 10px 10px 0 0;
    }
    .botth {
      border-radius: 0 0 10px 10px;
      border-bottom: none;
    }
  }
}
.dianji {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 400px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 20%;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  > div {
    width: 141px;
    margin-top: 100px;
    height: 200px;
    > div {
      position: relative;
      width: 141px;
      height: 141px;
      border-radius: 50%;
      img {
        width: 141px;
        height: 141px;
      }
      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 141px;
        height: 141px;
        border-radius: 50%;
        padding-top: 45px;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        p {
          color: #fff;
          margin: 0;
        }
      }
    }
    p {
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
    }
  }
}
.lianxi {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 25px;
  color: #3f8e0c;
}
.lianxiadress {
  width: 100%;

  text-align: center;
  font-size: 14px;
  color: #9d9d9d;
}
</style>