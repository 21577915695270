<template>
  <div class="content" ref="articleListBox">
    <component v-on:huanzujian="huanzujian" :is="zujian"></component>
  </div>
</template>

<script>
import zubu from "@/components/zubu.vue";
import xiazhi from "@/components/xiazhi.vue";
import qugan from "@/components/qugan.vue";
import jichu from "@/components/jichu.vue";
export default {
  components: {
    zubu,
    xiazhi,
    qugan,
    jichu,
  },
  data() {
    return {
      zujian: "zubu",
      gotop: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
   this.zujian =  this.$route.query.addres
  },
  created(){
    this.toTop()
  },
  methods: {
    huanzujian(data) {
      console.log(data);
      this.zujian = data;
      this.toTop()
    },
    handleScroll(e) {
      let scrolltop = e.target.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>


<style lang="scss" scoped>
.address-img {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
  }
}
.shuju {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 350px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 15%;
  border-bottom: 1px solid #ccc;
  > div {
    width: 20%;
    text-align: center;
    h3 {
      font-size: 18px;
      margin-top: 50px;

      color: rgb(94, 93, 93);
    }
    p {
      width: 100%;
      padding: 0 15%;
      margin-top: 30px;
      color: #9d9d9d;
      text-align: left;
    }
  }
}
.xiangmu {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 600px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 15%;
  margin-bottom: 50px;
  border-bottom: 1px solid #ccc;
  > div {
    position: relative;
    width: 25%;
    height: 500px;
    text-align: center;
    padding-top: 50px;
    margin: 0 20px;
    transition: all 0.5s;

    h3 {
      font-size: 18px;
      margin-top: 50px;

      color: rgb(94, 93, 93);
    }
    p {
      width: 100%;
      padding: 0 13%;
      margin-top: 20px;
      color: #9d9d9d;
      text-align: left;
    }
    div {
      position: absolute;

      width: 100%;
      left: 0;
      bottom: 50px;
      text-align: left;
      font-size: 16px;
      padding: 0 13%;
      img {
        float: right;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
  > div:hover {
    position: relative;
    width: 25%;
    height: 500px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
    transition: all 0.5s;
  }
}
.lianxi {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 25px;
  color: #3f8e0c;
}
.lianxiadress {
  width: 100%;

  text-align: center;
  font-size: 14px;
  color: #9d9d9d;
}
</style>